// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import './bootstrap_files'
import './material-kit-pro'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faSmileWink,
  faBullseyeArrow,
  faCarBuilding,
  faLocationArrow,
  faSmile,
  faPhoneOffice,
  faMapMarked,
  faArrowRight,
  faCalendarCheck,
  faPeopleArrows,
  faDollarSign,
  faPencilRuler,
  faShieldAlt,
  faSmileBeam,
  faBalanceScale,
  faUserChart,
  faHandsHeart,
  faUniversalAccess,
  faMobileAlt,
} from '@fortawesome/pro-duotone-svg-icons'

import {
  faFacebookSquare, faInstagramSquare, faLinkedin, faPinterestSquare, faTwitterSquare
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faSmileWink,
  faBullseyeArrow, 
  faCarBuilding,
  faLocationArrow,
  faSmile,
  faPhoneOffice,
  faMapMarked,
  faArrowRight,
  faCalendarCheck,
  faPeopleArrows,
  faDollarSign,
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faPinterestSquare,
  faLinkedin,
  faPencilRuler,
  faShieldAlt,
  faSmileBeam,
  faBalanceScale,
  faUserChart,
  faHandsHeart,
  faUniversalAccess,
  faMobileAlt
)

dom.watch()

window.mapLink = function() {
  let unikLocation = document.getElementById('unikLocation').innerText.replace('\<br\>', '').replace('\n', ', ');
  let address = encodeURIComponent(unikLocation);
  if ((navigator.platform.indexOf('iPhone') != -1) || (navigator.platform.indexOf('iPad') != -1) || (navigator.platform.indexOf('iPod') != -1)) {/* if we're on iOS, open in Apple Maps */
    window.open('http://maps.apple.com/?q=' + address);
  } else { /* else use Google */
    window.open('https://maps.google.com/maps?q=' + address);
  }
}
Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function () {
  let directions = document.getElementById('unikDirections');
  if ( directions != undefined ) {
    directions.addEventListener('click', function(e) {
      e.preventDefault();
      mapLink();
    })
  }
});
